<template>
  <div class="max-w-screen-md">
    <lf-card class="py-6 mb-8">
      <loader :is-loading="isLoading" />
      <div
        class="w-full space-y-10"
        :class="{
          'mt-5': client.can_create_underwriter,
          'my-5': !client.can_create_underwriter
        }"
      >
        <lf-switch
          name="underwritingDoneByLendflow"
          role="underwritingDoneByLendflow"
          v-model="underwritingDoneByLendflow"
          @toggle-changed="handleToggle"
          :disabled="!isAdmin || isLoading"
        >
          <div class="flex flex-col pr-10">
            <lf-h3>
              {{ $t("ORGANIZATION.UNDERWRITING_DONE_BY_LENDFLOW") }}
            </lf-h3>
            <span>
              {{ $t("ORGANIZATION.UNDERWRITING_DONE_BY_LENDFLOW_SUBTITLE") }}
            </span>
          </div>
        </lf-switch>
        <div
          v-if="isAdmin && client.can_create_underwriter"
          class="flex flex-col w-full"
        >
          <div class="flex justify-between items-center">
            <div class="w-1/2">
              <lf-h3>
                {{ $t("ORGANIZATION.SELF_FUNDING") }}
              </lf-h3>
              <span>
                {{ $t("ORGANIZATION.SELF_FUNDING_SUB") }}
              </span>
            </div>
            <div class="w-1/2 pl-20">
              <lf-dropdown-with-api-search
                :value="selectedFunder?.full_name || 'None'"
                :placeholder="$t('ORGANIZATION.SELECT_FUNDER')"
                :search-term="$t('SIDEBAR.FUNDERS')"
                :get-paginated-options="getApprovedFunders"
                accessor="full_name"
                name="funders"
                include-none
                @change="changeSelectedFunder"
              />
            </div>
          </div>
          <div
            class="flex justify-end items-center min-w-full border-t mt-6 pt-6"
          >
            <primary-button
              @click="handleSaveFunder"
              :disabled="isLoading"
              role="saveLinkedFunder"
            >
              {{ $t("ORGANIZATION.API_CONFIG.SAVE_CHANGES") }}
            </primary-button>
          </div>
        </div>
      </div>
    </lf-card>
  </div>
</template>

<script setup lang="ts">
// NOTE: There are a couple of bangs in front of <can_create_underwriter> becasuse
// in the template we are referencing context that is opposite of the property name
import { computed, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";
import fundersApiService from "@/services/modules/funders";

import LfDropdownWithApiSearch from "@/components/ui/inputs/LfDropdownWithApiSearch.vue";

import type { IClient } from "@/models/clients";
import type { IFunder } from "@/models/funders";

const { getters, dispatch } = useStore();
const { isAdmin } = useAuth();

const client = computed((): IClient => getters["clients/active"]);

const underwritingDoneByLendflow = ref(!client.value.can_create_underwriter);
const isLoading = ref(false);
const selectedFunder = ref<IFunder | undefined>(undefined);

const handleToggle = async (val: boolean) => {
  if (!isAdmin) {
    return;
  }
  isLoading.value = true;
  try {
    await dispatch("clients/updateClient", {
      data: {
        can_create_underwriter: !val
      }
    });
  } catch (error) {
    // return to previous state
    underwritingDoneByLendflow.value = !val;
  }
  isLoading.value = false;
};

const changeSelectedFunder = (funder: IFunder | undefined | null) => {
  selectedFunder.value = funder || undefined;
};

const handleSaveFunder = async (): Promise<void> => {
  isLoading.value = true;
  const funderId =
    selectedFunder.value === undefined ? null : selectedFunder.value.id;
  try {
    await dispatch("clients/storeLinkedFunder", funderId);
  } catch (e) {
    selectedFunder.value = undefined;
  } finally {
    isLoading.value = false;
  }
};

const getApprovedFunders = (params: Record<string, unknown> = {}) =>
  fundersApiService.getFunders({ ...params, approved: 1 });

watch(
  () => client.value.linked_funder,
  (newVal): void => {
    changeSelectedFunder(newVal);
  }
);

onMounted((): void => {
  changeSelectedFunder(client.value.linked_funder);
});
</script>
